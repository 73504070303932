<template>
  <div>
    <br />
    <div class="form-container">
      <b-row>
        <b-col md="auto">
          <label>
            Color
          </label>
        </b-col>

        <b-col md="auto">
          <v-swatches v-model="formData.color" inline></v-swatches>
        </b-col>
      </b-row>
      <br />
      <label>Name</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.name"
      ></b-form-input
      ><br /><br />

      <label>Description</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.description"
      ></b-form-input
      ><br /><br />

      <label>Harga (Rp, Free=0)</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.price"
        type="number"
      ></b-form-input
      ><br /><br />

      <label>Type</label> <br />
      <b-form-select
        :options="types"
        v-model="formData.type"
        class="w-auto"
      ></b-form-select>
      <br /><br />

      <b-row class="mt-4-5">
        <b-col md="auto">
          <label>Thumbnail</label><br />
          <div class="upload-image">
            <div class="upload-image__item">
              <div class="upload-image__top">
                <img :src="formData.thumbnail.src" alt="img" style="max-height:108px" />
                <div class="img-remove" @click="removeThumbnail()" v-if="formData.thumbnail.src !='/img/thumbnail.png'">
                  X Remove
                </div>
              </div>

              <div class="upload-image__bottom">
                <input
                  type="file"
                  name="file-image"
                  id="file-image"
                  class="hidden-input"
                  @change="uploadThumbnail($event)"
                />
                <label for="file-image" class="file-upload">
                  <fa-icon icon="upload" class="my-icon" />
                  Change Thumbnail
                </label>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      
      <my-button type="blue" size="lg" block class="mt-4-5" rounded v-if="!showLoading" @click="save">
        SIMPAN
      </my-button>
      <my-button type="blue" size="lg" block class="mt-4-5" rounded disabled v-else>
        <b-spinner small></b-spinner>
        <span class="sr-only">Loading...</span>
      </my-button>

    </div>
    <br />

    <div class="form-container">
      <h4 class="color3 mt-5">
        BACKGROUND 
      </h4>
        <my-button type="orange" size="sm" rounded class="mt-3" @click="setBackground">
            <fa-icon icon="plus-circle" class="mr-1"></fa-icon>
            SET BACKGROUND
        </my-button>
      <br/>
      <hr/>

      <h4 class="color3 mt-5">
        IMAGE ITEM
      </h4>

      <div class="course-collection" v-if="item.length > 0">
        <div v-for="(it, i) in item">
          <hr v-if="i != 0 && i < item.length"/>
          <div class="space-between align-start">
            <div class="flex-1">
              <div class="align-start">
                <img :src="it.resource_url ? it.resource_url : '/img/thumbnail.png'" style="height: 50px" />
              </div>
            </div>

            <div class="align-center">
              <b-button
                size="sm"
                class="mr-2 action-button"
                variant="outline-secondary"
                @click="editItem(i, 'image', 'Edit Image Item', 'item')"
              >
                <fa-icon icon="pencil-alt" />
              </b-button>
              <b-button
                size="sm"
                class="mr-2 action-button"
                variant="outline-secondary"
                @click="handleDelete(it.id)"
              >
                <fa-icon icon="trash-alt" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <my-button type="orange" size="sm" rounded class="mt-3" @click="newItem('image', 'Add New Item')">
        <fa-icon icon="plus-circle" class="mr-1"></fa-icon>
        ADD NEW IMAGE ITEM
      </my-button>
      <br/>
      <hr/>

      <h4 class="color3 mt-5">
        TEXT ITEM
      </h4>

      <div class="course-collection" v-if="itemText.length > 0">
        <div v-for="(it, i) in itemText">
          <hr v-if="i != 0 && i < itemText.length"/>
          <div class="space-between align-start">
            <div class="flex-1">
              <div class="align-start">
                <p class="flex-1 mr-2">
                  {{ it.resource }}
                </p>
              </div>
            </div>

            <div class="align-center">
              <b-button
                size="sm"
                class="mr-2 action-button"
                variant="outline-secondary"
                @click="editItem(i, 'text', 'Edit Text Item', 'itemText')"
              >
                <fa-icon icon="pencil-alt" />
              </b-button>
              <b-button
                size="sm"
                class="mr-2 action-button"
                variant="outline-secondary"
                @click="handleDelete(it.id)"
              >
                <fa-icon icon="trash-alt" />
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <my-button type="orange" size="sm" rounded class="mt-3" @click="newItem('text', 'Add New Text')">
        <fa-icon icon="plus-circle" class="mr-1"></fa-icon>
        ADD NEW TEXT ITEM
      </my-button>
      <br/>
      <hr/>
    </div>

    <!-- IMAGE MODAL -->
    <b-modal
      v-model="contentModal"
      id="contentModal"
      :title="currentItem.title"
      hide-footer
      centered
    >
      <div class="content-modal-container">
        <b-row class="mt-4-5">
          <b-col md="auto">
            <b-col md="auto">
                <div class="upload-image">
                    <div class="upload-image__item">
                        <div class="upload-image__top">
                        <label>Width</label><br />
                            <b-form-input
                            placeholder=".."
                            v-model="currentItem.width"
                            ></b-form-input>
                        </div>
                        <br/>
                        <div class="upload-image__bottom">
                            <label>Height</label><br />
                            <b-form-input
                            placeholder=".."
                            v-model="currentItem.height"
                            ></b-form-input>
                        </div>
                    </div>
                </div>
            </b-col>
            <br/>
             <b-col md="auto">
                <div class="upload-image">
                <div class="upload-image__item">
                    <div class="upload-image__top">
                    <label>Koordinat X</label><br />
                        <b-form-input
                        placeholder=".."
                        v-model="currentItem.xaxis"
                        ></b-form-input>
                    </div>
                    <br/>
                    <div class="upload-image__bottom">
                        <label>Koordinat Y</label><br />
                        <b-form-input
                        placeholder=".."
                        v-model="currentItem.yaxis"
                        ></b-form-input>
                    </div>
                </div>
                </div>
            </b-col>
          </b-col>
          <br/>
          <b-col md="auto">
            <label>Image</label><br />
            <div class="upload-image">
              <div class="upload-image__item">
                <div class="upload-image__top">
                  <img :src="currentItem.thumbnail.src" alt="img" style="max-height:108px" />
                  <div class="img-remove" @click="removeThumbnail('item')" v-if="currentItem.thumbnail.src !='/img/thumbnail.png'">
                    X Remove
                  </div>
                </div>

                <div class="upload-image__bottom">
                  <input
                    type="file"
                    name="file-item-resource"
                    id="file-item-resource"
                    class="hidden-input"
                    @change="uploadThumbnailItem($event)"
                  />
                  <label for="file-item-resource" class="file-upload">
                    <fa-icon icon="upload" class="my-icon" />
                    Upload Image
                  </label>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        </b-row>
        <div class="justify-end mt-4 mb-3">
          <my-button
            type="outline-blue"
            size="lg"
            class="mr-2"
            @click="contentModal = false"
          >
            CANCEL
          </my-button>

          <my-button type="blue" size="lg" @click="saveItem" v-if="!showLoading">
            SAVE
          </my-button>
          <my-button type="blue" size="lg" v-else disabled>
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
        </div>
      </div>
    </b-modal>

    <!-- TEXT MODAL -->
    <b-modal
      v-model="modalText"
      id="modalText"
      :title="currentItem.title"
      hide-footer
      centered
    >
      <div class="content-modal-container">
        <label>Font Color</label><br />
        <verte v-model="currentItem.color" picker="wheel" model="hex"></verte>
        <br/><br/>
        <label>Font</label><br />
        <b-form-select
            :options="formDataFont"
            v-model="currentItem.font"
            class="w-auto"
        ></b-form-select>
        <br/><br/>
        <label>Text</label><br />
        <b-form-input
        placeholder=".."
        v-model="currentItem.resource"
        ></b-form-input
        ><br /><br />
        <label>Koordinat X</label><br />
        <b-form-input
        placeholder=".."
        v-model="currentItem.xaxis"
        ></b-form-input
        ><br /><br />
        <label>Koordinat Y</label><br />
        <b-form-input
        placeholder=".."
        v-model="currentItem.yaxis"
        ></b-form-input
        ><br /><br />
        <label>Size</label><br />
        <b-form-input
        placeholder=".."
        v-model="currentItem.size"
        ></b-form-input
        ><br /><br />
        <label>Line Height</label><br />
        <b-form-input
        placeholder=".."
        v-model="currentItem.height"
        ></b-form-input
        ><br /><br />
        <label>Width</label><br />
        <b-form-input
        placeholder=".."
        v-model="currentItem.width"
        ></b-form-input
        ><br /><br />
        <label>Padding</label><br />
        <b-form-input
        placeholder=".."
        v-model="currentItem.padding"
        ></b-form-input
        ><br /><br />
        <label>Font Style</label><br />
        <b-form-select
            :options="formDataFontStyle"
            v-model="currentItem.style"
            class="w-auto"
        ></b-form-select>
        <br /><br />
        <label>Font Align</label><br />
        <b-form-select
            :options="formDataFontAlign"
            v-model="currentItem.align"
            class="w-auto"
        ></b-form-select>
        <br /><br />
        
        <div class="justify-end mt-4 mb-3">
          <my-button
            type="outline-blue"
            size="lg"
            class="mr-2"
            @click="modalText = false"
          >
            CANCEL
          </my-button>

          <my-button type="blue" size="lg" @click="saveItem" v-if="!showLoading">
            SAVE
          </my-button>
          <my-button type="blue" size="lg" v-else disabled>
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
        </div>
      </div>
    </b-modal>

    <!-- MODAL FOR SET BACKGROUND -->
    <b-modal
      v-model="setBackgroundModal"
      id="setBackgroundModal"
      title="SET BACKGROUND"
      hide-footer
      centered
    >
      <div class="content-modal-container">
         <label>Type</label><br />
         <b-form-select
            :options="formDataBackground"
            v-model="currentBg.type"
            class="w-auto"
        ></b-form-select>

        <div v-if="currentBg.type == 'color'" class="mt-4">
            <label>Text</label><br />
            <v-swatches v-model="currentBg.color" inline></v-swatches>
        </div>
        <b-row class="mt-4-5" v-if="currentBg.type == 'image'">
          <b-col md="auto">
            <label>Background Image</label><br />
            <div class="upload-image">
              <div class="upload-image__item">
                <div class="upload-image__top">
                  <img :src="currentBg.src" alt="img" style="max-height:108px" />
                  <div class="img-remove" @click="removeThumbnail('bg')" v-if="currentBg.src !='/img/thumbnail.png'">
                    X Remove
                  </div>
                </div>

                <div class="upload-image__bottom">
                  <input
                    type="file"
                    name="file-image-item"
                    id="file-image-item"
                    class="hidden-input"
                    @change="uploadBg($event)"
                  />
                  <label for="file-image-item" class="file-upload">
                    <fa-icon icon="upload" class="my-icon" />
                    Upload Background Image
                  </label>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="justify-end mt-4 mb-3">
          <my-button
            type="outline-blue"
            size="lg"
            class="mr-2"
            @click="setBackgroundModal = false"
          >
            CANCEL
          </my-button>

          <my-button type="blue" size="lg" @click="saveBg" v-if="!showLoading">
            SAVE
          </my-button>
          <my-button type="blue" size="lg" v-else disabled>
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";
import VSwatches from 'vue-swatches';
import Verte from 'verte';
import 'verte/dist/verte.css';

// Import the styles too, typically in App.vue or main.js
import 'vue-swatches/dist/vue-swatches.css'

export default {
  components: { VSwatches, Verte },
  data() {
    return {  
      modalText: false,
      color: null,
      currentBg: {
        src: '/img/thumbnail.png',
        data: null,
        type: 'image',
        color: '',
        data: null,
        deleteBg: 0
      },
      formDataBackground: [
          { value: 'image' , text: 'Background Image' },
          { value: 'color' , text: 'Color' },
      ],
      formDataFont: [],
      formDataFontStyle: [
          { value: 'null' , text: 'No Style' },
          { value: 'italic' , text: 'Italic' },
          { value: 'bold' , text: 'Bold' },
          { value: 'underline' , text: 'Underline' }
      ],
      formDataFontAlign: [
          { value: 'left' , text: 'Left' },
          { value: 'right' , text: 'Right' },
          { value: 'center' , text: 'Center' },
          { value: 'justify' , text: 'Justify' }
      ],
      setBackgroundModal: false,
      contentModal: false,
      showLoading: false,
      formData: {
        name: '',
        price: 0,
        description: '',
        thumbnail: {
          src: "/img/thumbnail.png",
          data: null,
          raw: ""
        },
        bg: {
          src: "/img/thumbnail.png",
          data: null,
          raw: "",
          color: ""
        },
        type: 1,
        deleteThumbnail: 0,
        color: ''
      },
      types: [],
      categories:[],
      currentItem:{
        id: null,
        resource: "",
        title: "",
        xaxis: 0,
        yaxis: 0,
        width: 0,
        height: 0,
        item_type: 'image',
        thumbnail: {
          src: "/img/thumbnail.png",
          data: null,
          raw: ""
        },
        deleteThumbnail: 0,
        style: null,
        font: null,
        padding: 0,
        align: 'left',
        size: 0,
        color: ''
      },
      item:[],
      itemText: []
    };
  },

  computed: {
    ...mapGetters({
      token: types.TOKEN
    })
  },
  
  methods: {
    ...mapActions({
      updateMarketingKit: types.UPDATE_MARKETING_PRO,
      getTypeList: types.GET_MARKETING_PRO_TYPE_LIST,
      getMarketingKit: types.GET_MARKETING_PRO_DETAIL,
      createMarketingItem: types.CREATE_MARKETING_PRO_ITEM,
      updateMarketingItem: types.UPDATE_MARKETING_PRO_ITEM,
      deleteItem:types.DELETE_MARKETING_PRO_ITEM,
      backgroundPro:types.BACKGROUND_MARKETING_PRO,
      getFontList: types.GET_FONT_LIST
    }),
    fontList() {
      this.isBusy = true;
      this.getFontList()
        .then(response => {
          var res = response;
          this.formDataFont = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              value: res[i].name,
              text: res[i].name
            };
            this.formDataFont.push(dataItem);
          }
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    getDetail() {
      this.getMarketingKit({id:this.$route.params.id})
      .then(response => {
        this.formData.name = response[0].name;
        this.formData.price = response[0].price;
        this.formData.color = response[0].theme_color;
        this.formData.description = response[0].description;
        this.formData.type = response[0].type;
        this.formData.thumbnail.raw = response[0].thumbnail;
        this.formData.thumbnail.src = response[0].thumbnail ? response[0].thumbnail_url : '/img/thumbnail.png';
        this.formData.bg.src = response[0].background_resource && response[0].background_type=='image' ? response[0].background_resource_url : '/img/thumbnail.png';
        this.formData.bg.type = response[0].background_type;
        this.formData.bg.color = response[0].background_type=='color' ? response[0].background_resource : '';
        this.item = [];
        this.itemText = [];
        for (var i = 0; i < response[0].item.length; i++) {
          if(response[0].item[i].item_type=='image') {
            this.item.push(response[0].item[i]);
          } else {
            this.itemText.push(response[0].item[i]);
          }
        }
      })
      .catch(error => {
        this.toastError('b-toaster-top-center', error);
      })
    },
    save() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("id", this.$route.params.id);
      newFormData.append("description", this.formData.description);
      newFormData.append("name", this.formData.name);
      newFormData.append("price", this.formData.price);
      newFormData.append("type", this.formData.type);
      newFormData.append("theme_color", this.formData.color);
      newFormData.append("thumbnail", this.formData.thumbnail.data);
      newFormData.append("deleteThumbnail", this.formData.deleteThumbnail);
      this.updateMarketingKit(newFormData)
        .then(response => {
          this.showLoading = false;
          this.toastSuccess('b-toaster-top-center', "Updated");
          this.getDetail();
        })
        .catch(error => {
          this.showLoading = false;
          this.toastError('b-toaster-top-center', error);
        });
    },
    toastSuccess(toaster, message, append = false) {
      this.$bvToast.toast(message, {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 3000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 3000
      })
    },
    removeThumbnail(data){
      if(data == 'item') {
        this.currentItem.thumbnail.data = null;
        this.currentItem.thumbnail.src = '/img/thumbnail.png';
        this.currentItem.deleteThumbnail = 1;
      } else if(data == 'bg') {
        this.currentBg.data = null;
        this.currentBg.src = '/img/thumbnail.png';
        this.currentBg.deleteBg = 1;
      } else {
        this.formData.thumbnail.data = null;
        this.formData.thumbnail.src = '/img/thumbnail.png';
        this.formData.deleteThumbnail = 1;
      }
    },
    uploadThumbnail(event) {
      var fileData = event.target.files[0];
      this.formData.thumbnail.data = fileData;
      this.formData.thumbnail.src = URL.createObjectURL(fileData);
    },
    uploadBg(event) {
      var fileData = event.target.files[0];
      this.currentBg.data = fileData;
      this.currentBg.src = URL.createObjectURL(fileData);
    },
    uploadThumbnailItem(event) {
      var fileData = event.target.files[0];
      this.currentItem.thumbnail.data = fileData;
      this.currentItem.thumbnail.src = URL.createObjectURL(fileData);
    },
    typeList(level_id = null) {
      this.getTypeList()
        .then(response => {
          var res = response;
          this.types = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              value: res[i].id,
              text: res[i].name
            };
            this.types.push(dataItem);
          }
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    setBackground(){
      this.currentBg.src = this.formData.bg.src;
      this.currentBg.type = this.formData.bg.type ? this.formData.bg.type : 'image';
      this.currentBg.color = this.formData.bg.color;
      this.setBackgroundModal = true;
    },
    newItem(type, title) {
      this.currentItem = {
        id: null,
        title: title,
        xaxis: 0,
        item_type: type,
        yaxis: 0,
        width: 0,
        height: 0,
        thumbnail: {
          src: "/img/thumbnail.png",
          data: null,
          raw: ""
        },
        resource:"",
        deleteThumbnail: 0,
        style: null,
        padding: 0
      }
      if(type=='image') {
        this.contentModal = true;
      } else {
        this.currentItem.font = this.formDataFont[0] ? this.formDataFont[0].value : null;
        this.currentItem.align = 'left';
        this.currentItem.size = 0;
        this.currentItem.color = "#000000";
        this.modalText = true;
      }
    },
    saveBg() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("id", this.$route.params.id);
      newFormData.append("color", this.currentBg.color);
      newFormData.append("bg", this.currentBg.data);
      newFormData.append("type", this.currentBg.type);
      newFormData.append("deleteBg", this.currentBg.deleteBg);
      this.backgroundPro(newFormData)
        .then(response => {
          this.showLoading = false;
          this.toastSuccess('b-toaster-top-center', "Background updated");
          this.setBackgroundModal = false;
          this.getDetail();
        })
        .catch(error => {
          this.showLoading = false;
          this.toastError('b-toaster-top-center', error);
        });
    },
    saveItem() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("marketing_id", this.$route.params.id);
      newFormData.append("xaxis", this.currentItem.xaxis);
      newFormData.append("yaxis", this.currentItem.yaxis);
      newFormData.append("width", this.currentItem.width);
      newFormData.append("height", this.currentItem.height);
      newFormData.append("item_type", this.currentItem.item_type);
      newFormData.append("font", this.currentItem.font);
      newFormData.append("style", this.currentItem.style);
      newFormData.append("padding", this.currentItem.padding);
      if(this.currentItem.item_type == 'image') {
        newFormData.append("resource", this.currentItem.thumbnail.data);
      } else {
        newFormData.append("align", this.currentItem.align);
        newFormData.append("size", this.currentItem.size);
        newFormData.append("color", this.currentItem.color);
        newFormData.append("resource", this.currentItem.resource);
      }
      if(this.currentItem.id) {
        newFormData.append("id", this.currentItem.id);
        newFormData.append("deleteThumbnail", this.currentItem.deleteThumbnail);
        this.updateMarketingItem(newFormData)
          .then(response => {
            this.showLoading = false;
            this.toastSuccess('b-toaster-top-center', "Item updated");
            if(this.currentItem.item_type=='image') {
              this.contentModal = false;
            } else {
              this.modalText = false;
            }
            this.getDetail();
          })
          .catch(error => {
            this.showLoading = false;
            this.toastError('b-toaster-top-center', error);
          });
      } else {
        this.createMarketingItem(newFormData)
          .then(response => {
            this.showLoading = false;
            this.toastSuccess('b-toaster-top-center', "New item added");
            if(this.currentItem.item_type=='image') {
              this.contentModal = false;
            } else {
              this.modalText = false;
            }
            this.getDetail();
          })
          .catch(error => {
            this.showLoading = false;
            this.toastError('b-toaster-top-center', error);
          });
      }
    },
    editItem(idx, type, title, item) {
      this.currentItem = {
        title: title,
        id: this[item][idx].id,
        xaxis: this[item][idx].xaxis,
        item_type: type,
        yaxis: this[item][idx].yaxis,
        width: this[item][idx].width,
        height: this[item][idx].height,
        title: title,
        thumbnail: {
          src: this[item][idx].resource && type=='image' ? this[item][idx].resource_url : "/img/thumbnail.png",
          data: null,
          raw: this[item][idx].resource
        },
        resource: this[item][idx].resource,
        style: this[item][idx].style,
        font: this[item][idx].font,
        padding: this[item][idx].padding,
        size: this[item][idx].size
      }
      if(type=='image') {
        this.contentModal = true;
      } else {
        this.currentItem.align = this[item][idx].align ? this[item][idx].align : 'left';
        this.currentItem.color = this[item][idx].color ? this[item][idx].color : "#000000";
        this.modalText = true;
      }
    },
    handleDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Delete item?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteItem({id:idx})
            .then(response => {
              this.toastSuccess('b-toaster-top-center', "Item dihapus");
              this.getDetail();
            })
            .catch(error => {
              this.toastError('b-toaster-top-center', error);
            })
          }
        })
        .catch(err => {
          this.toastError('b-toaster-top-center', err);
        });
    }
  },

  mounted() {
    this.getDetail();
    this.typeList();
    this.fontList();
  }
};
</script>

<style>

.button-ya {
  border: 1px solid #3bbaed;
  color: #3bbaed;
  margin-right: 11px;
  background: #fff;
  min-width: 22px;
  margin-top: 11px;
}

@media (min-width: 576px) {
  #modal-icon .modal-lg {
    max-width: 544px;
  }
 
}

.content-modal-container {
  padding: 20px 44px;
}

@media screen and (max-width: 768px) {
  .content-modal-container {
    padding: 20px 0;
  }
}

#contentModal .modal-dialog, 
#setBackgroundModal .modal-dialog,
#modalText .modal-dialog {
  width: 708px;
  max-width: 95%;
}

</style>
